const { mainConfig } = require('./variables');
const curScriptElement = document.currentScript;
/**
 * Checks if Apple Pay is possible in the current environment.
 * @return {boolean} Boolean to check if Apple Pay is possible
*/

const _isWalletActive = async () => {
  try {
    return await window.ApplePaySession.canMakePaymentsWithActiveCard(mainConfig.apple_merchant_id);
  } catch(error) {
    window[curScriptElement.dataset.error]({ status: 'cancel', message: 'Apple payment is not enabled' });
    return false;
  }
};
exports._isWalletActive = _isWalletActive;

const _applePayAvailable = function () {
  if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
    return true;
  }
  return false;
};
exports._applePayAvailable = _applePayAvailable;

const _openAppleWalletApp = async function (merchantIdentifier) {
  try {
    await window.ApplePaySession.openPaymentSetup(merchantIdentifier)
      .then((response) => {
        if(!response){
          window[curScriptElement.dataset.error]({ status: 'error', ...response });
        }
      })
      .catch(function (err) {
        window[curScriptElement.dataset.error]({ status: 'error', ...err });
      });
  } catch (error) {
    window[curScriptElement.dataset.error]({ status: 'cancel', message: 'Apple wallet is not accessible' });
  }
};
exports._openAppleWalletApp = _openAppleWalletApp;


const _startApplePaySession = function () {
  let appleConfigs = {
    currencyCode: mainConfig.currency_code,
    countryCode: mainConfig.country_code,
    merchantCapabilities: (mainConfig.applePayConfig && mainConfig.applePayConfig.merchantCapabilities && mainConfig.applePayConfig.merchantCapabilities.length)?mainConfig.applePayConfig.merchantCapabilities:['supports3DS'],
    supportedNetworks: ['amex', 'masterCard', 'maestro', 'visa', 'mada'],
    total: {
      label: mainConfig.shop_name,
      amount: mainConfig.amount,
      type: 'final',
    },
  };
  var applePaySession = new window.ApplePaySession(6, appleConfigs);
  _handleApplePayEvents(applePaySession);
  applePaySession.begin();
};
exports._startApplePaySession = _startApplePaySession;

const _handleApplePayEvents = function (appleSession) {
  appleSession.onvalidatemerchant = function (event) {
    _validateApplePaySession(
      event.validationURL,
      function (merchantSession) {
        if (merchantSession.callback_payload && merchantSession.callback_payload.status == 'error') {
          window[curScriptElement.dataset.error](merchantSession.callback_payload);
        } else if (merchantSession.err !== undefined) {
          appleSession.abort();
          window[curScriptElement.dataset.error]({ status: 'error', message: merchantSession.err });
        }
        appleSession.completeMerchantValidation(merchantSession);
      }
    );
  };

  appleSession.oncancel = function (message) {
    window[curScriptElement.dataset.cancel]({ status: 'cancel', message: message || 'Payment is cancelled by Apple' });
  };

  appleSession.onpaymentauthorized = function (event) {
    _performTransaction(event.payment, function (outcome) {
      if (outcome.approved) {
        appleSession.completePayment({status: window.ApplePaySession.STATUS_SUCCESS});
        if(outcome.callback_payload){
          window[curScriptElement.dataset.success](outcome.callback_payload);
        } else {
          window[curScriptElement.dataset.success]({ status: 'success', message: '', ...outcome });
        }
      } else if (!outcome.approved) {
        let err = new window.ApplePayError('unknown');
        appleSession.completePayment({
          status: window.ApplePaySession.STATUS_FAILURE,
          errors: [err],
        });
        if (outcome.callback_payload && outcome.callback_payload.status == 'canceled') {
          if(outcome.callback_payload.payment_gateway_info.pg_name === 'kpay'){
            appleSession.abort();
          } 
          window[curScriptElement.dataset.cancel](outcome.callback_payload);
        } else if (outcome.callback_payload && outcome.callback_payload.status == 'error') {
          window[curScriptElement.dataset.error](outcome.callback_payload);
        }
      } else if(outcome.err) {
        let err = new window.ApplePayError('unknown');
        appleSession.completePayment({
          status: window.ApplePaySession.STATUS_FAILURE,
          errors: [err],
        });
        window[curScriptElement.dataset.error]({ status: 'error', message: outcome.err, ...outcome });
      } else {
        appleSession.completePayment(window.ApplePaySession.STATUS_FAILURE);
        window[curScriptElement.dataset.error]({ status: 'error', message: outcome.message || '', ...outcome });        
      }
    });
  };
};

const _validateApplePaySession = function (appleUrl, callback) {
  let url = mainConfig.validation_url;
  fetch((url), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${mainConfig.apiKey}`,
    },
    body: JSON.stringify({
      apple_url: appleUrl,
      session_id: mainConfig.session_id,
      code: mainConfig.code,
    }),
  })
    .then(async (response) =>  {
      if (response.ok) {
        return response.json();
      }
      else {
        const data = await response.json();
        if(data.callback_payload){
          return data;
        } else {
          throw new Error(response.status + ' Failed merchant validation ');
        }
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch(function (err) {
      callback({
        err,
      });
    });
};

const _performTransaction = function (details, callback) {
  fetch(mainConfig.payment_url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${mainConfig.apiKey}`,
    },
    body: JSON.stringify({
      apple_pay_payload: {
        token: details,
      },
      currency_code:mainConfig.currency_code,
      amount:mainConfig.amount,
      session_id: mainConfig.session_id,
      code: mainConfig.code,
    }),
  })
    .then(async (response) =>{
      if (response.ok) {
        return response.json();
      }
      else {
        const data = await response.json();
        if(data.callback_payload){
          return data;
        } else {
          throw new Error(response.status + ' Failed Fetch ');
        }
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      callback({
        err,
      });
    });
};