exports.mainConfig = {
  merchant_id: '',
  session_id: null,
  lang: 'en',
  render: true,
  selector: '',
};

exports.method = {
  type: '',
  code: '',
  submit_url: '',
  cancel_url: '',
  payment_url: '',
  redirect_url:'',
  id: '',
  ccv_required: '',
  can_save_card: '',
};

exports.elements = {
  sdkChild: 'ottu__sdk-child',
  threeDS: 'threeDs-challenge',
  amount: 'sdk-amount',
  currency: 'sdk-currency',
  blockMethodsLoader: 'sdk-block-methods-loader',
  blockMobileAppleButton: 'ottu__sdk-block-mobile-apple-button',
  blockMobileGoogleButton: 'ottu__sdk-block-mobile-google-button',
  blockMethods: 'ottu__sdk-sdk-block-methods',
  block_not_supported: 'ottu__sdk-not-supported',
  savedCards: 'ottu__sdk-saved-cards',
  savedCardsBlock: 'ottu__sdk-saved-cards-block',
  accordionItem: 'ottu__sdk-accordion-item',
  accordionItemHeader: 'ottu__sdk-accordion-item-header',
  accordionItemRadio: 'ottu__sdk-payment-radio',
  payButton: 'pay-button',
  payCancel: 'pay-cancel',
  cardSaveBlcok: 'ottu__sdk-card-save',
  blockButtonsLoader: 'ottu__sdk-block-buttons-loader',
  blockButtons: 'ottu__sdk-block-buttons',
  blockAppleButton: 'ottu__sdk-block-apple-button',
  blockGoogleButton: 'ottu__sdk-block-google-button',
  modal: 'ottu__sdk-save-card-delete-modal',
  saveCardDeleteButton: 'ottu__sdk-del-btn',
  modalOverlay: 'ottu__sdk-overlay',
  blockIframeLoader: 'ottu__sdk-iframe-loader',
  applePayButton: 'ckoApplePayButton',
  googlePayButton: 'ckoGooglePayButton',
  appleSetupButton: 'ckoAppleSetupButton',
  amountTitle: 'ottu__sdk-amount-title',
  feesBlock: 'ottu__sdk-fees-block',
  fees: 'ottu__sdk-fees',
  feesLabel: 'ottu__sdk-fee-label',
  feesCurrency: 'ottu__sdk-fees-currency',
  totalBlock: 'ottu__sdk-total-block',
  total: 'ottu__sdk-total1',
  totalCurrency: 'ottu__sdk-total-currency',
  applePayUnavailableText: 'applepay-unavailable-text',
  stcPayMobileNumberBlock: 'stcPay-mobile-Number-Block',
  stcPayMobileNumber: 'stcPay-mobile-Number',
  stcOTPBlock: 'stcPay-OTP-block',
  stcPayOTP: 'stcPayOTP'
};

exports.cardsSvg = {
  VISA: process.env.SERVER_URL+'/v2/img/VISA.svg',
  MASTERCARD: process.env.SERVER_URL+'/v2/img/MASTERCARD.svg',
  AMEX: process.env.SERVER_URL+'/v2/img/AMEX.svg',
  // MADA: process.env.SERVER_URL+'/v2/img/MADA.svg',
  DISCOVER: process.env.SERVER_URL+'/v2/img/DISCOVER.svg',
  JCB: process.env.SERVER_URL+'/v2/img/JCB.svg',
  DINERS: process.env.SERVER_URL+'/v2/img/DINERS.svg',
  CUP: process.env.SERVER_URL+'/v2/img/CUP.svg',
  MAESTRO: process.env.SERVER_URL+'/v2/img/MAESTRO.svg',
  stc_pay: process.env.SERVER_URL+'/v2/img/STCPAY.svg',
  // RUPAY: process.env.SERVER_URL+'/v2/img/RUPAY.svg',
  // UATP: process.env.SERVER_URL+'/v2/img/UATP.svg'
};

exports.logoSvg = {
  cvv: '<svg id="Group_1354" class="hide-tag-display" data-name="Group 1354" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Path_1041" data-name="Path 1041" d="M0,0H24V24H0Z" fill="none"></path><rect id="Rectangle_82" data-name="Rectangle 82" width="18" height="14" rx="3" transform="translate(3.378 4.688)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><line id="Line_54" data-name="Line 54" x2="18" transform="translate(3.378 9.688)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_55" data-name="Line 55" x2="0.01" transform="translate(7 15)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_56" data-name="Line 56" x2="2" transform="translate(11.378 14.688)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line></svg>',
  cardNumber: '<svg id="Group_1356" data-name="Group 1356" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Path_1034" data-name="Path 1034" d="M0,0H24V24H0Z" fill="none"></path><rect id="Rectangle_12" data-name="Rectangle 12" width="19.855" height="16.438" rx="3" transform="translate(2.443 3.781)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></rect><ellipse id="Ellipse_3" data-name="Ellipse 3" cx="2.363" cy="1.891" rx="2.363" ry="1.891" transform="translate(7.299 8.087)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></ellipse><line id="Line_49" data-name="Line 49" x2="1.891" transform="translate(15.817 8)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_50" data-name="Line 50" x2="1.891" transform="translate(15.817 12)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line><line id="Line_51" data-name="Line 51" x2="9.453" transform="translate(7.733 16)" fill="none" stroke="#c7c7c7" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line></svg>',
  delete: '<svg xmlns="http://www.w3.org/2000/svg" width="19.615" height="19.5" viewBox="0 0 19.615 19.5"><g id="Delete" transform="translate(0.75 0.75)"><path id="Stroke_1" data-name="Stroke 1" d="M15.159,0s-.58,6.553-.917,9.314a2.312,2.312,0,0,1-2.519,2.115q-4.181.069-8.363,0A2.283,2.283,0,0,1,.915,9.32C.577,6.536,0,0,0,0" transform="translate(1.478 6.536)" fill="none" stroke="#f0252b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"></path><path id="Stroke_3" data-name="Stroke 3" d="M18.115.5H0" transform="translate(0 2.895)" fill="none" stroke="#f0252b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"></path><path id="Stroke_3-2" data-name="Stroke 3" d="M4.666.307,0,0" transform="translate(11.598 8.759) rotate(94)" fill="none" stroke="#f0252b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"></path><path id="Stroke_3-3" data-name="Stroke 3" d="M4.663-.043,0,.264" transform="matrix(0.07, 0.998, -0.998, 0.07, 6.701, 8.743)" fill="none" stroke="#f0252b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"></path><path id="Stroke_5" data-name="Stroke 5" d="M11.134,3.395A1.728,1.728,0,0,1,9.409,2.107L9.149.923A1.352,1.352,0,0,0,7.828,0H3.306A1.352,1.352,0,0,0,1.985.923l-.26,1.183A1.728,1.728,0,0,1,0,3.395" transform="translate(3.49)" fill="none" stroke="#f0252b" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"></path></g></svg>',
  modalX: '<svg xmlns="http://www.w3.org/2000/svg" width="17.828" height="17.828" viewBox="0 0 17.828 17.828"><g id="Group_1412" data-name="Group 1412" transform="translate(-802.086 -494.086)"><line id="Line_77" data-name="Line 77" x1="15" y2="15" transform="translate(803.5 495.5)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="2"></line><line id="Line_78" data-name="Line 78" x2="15" y2="15" transform="translate(803.5 495.5)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="2"></line></g></svg>',
  back: '<svg width="25px" height="30px" viewBox="0 0 512.00 512.00" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title>ionicons-v5-a</title><polyline points="244 400 100 256 244 112" style="fill:none;stroke:#828282;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px"></polyline><line x1="120" y1="256" x2="412" y2="256" style="fill:none;stroke:#828282;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px"></line></g></svg>',
  loading: '<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path></svg>',
  apple: '<svg style="position: relative; top : -1px" xmlns="http://www.w3.org/2000/svg" width="15.029" height="16.485" viewBox="0 0 15.029 18.485"><g id="Group_1431" data-name="Group 1431" transform="translate(0 0)"><path id="Path_1068" data-name="Path 1068" d="M104.678,136.286a3.894,3.894,0,0,1-.132,1.353,4,4,0,0,1-2.392,2.723,2.577,2.577,0,0,1-1.193.184,2.324,2.324,0,0,1,.008-.774,4.194,4.194,0,0,1,3.173-3.41c.127-.03.255-.051.383-.077Z" transform="translate(-93.482 -136.286)" fill="#fff"></path><path id="Path_1069" data-name="Path 1069" d="M82.509,157.891a4.106,4.106,0,0,0,.47,7.279c-.011.039-.022.084-.036.128a10.887,10.887,0,0,1-1.846,3.339,6.424,6.424,0,0,1-.684.75,2.315,2.315,0,0,1-2.544.484c-.324-.119-.637-.272-.964-.381a3.512,3.512,0,0,0-2.508.122c-.327.133-.654.271-.991.37a2.1,2.1,0,0,1-2.076-.514,7.811,7.811,0,0,1-1.146-1.317,11.622,11.622,0,0,1-2.012-4.527,8.44,8.44,0,0,1-.075-3.462,5.071,5.071,0,0,1,1.8-3.076,4.284,4.284,0,0,1,2.718-.961,4.032,4.032,0,0,1,1.422.311c.344.128.688.259,1.032.388a1.421,1.421,0,0,0,1.025,0c.463-.173.926-.348,1.394-.51a4.057,4.057,0,0,1,1.478-.256,4.338,4.338,0,0,1,3.2,1.387c.1.113.193.237.288.357C82.471,157.828,82.488,157.859,82.509,157.891Z" transform="translate(-67.951 -151.589)" fill="#fff"></path></g></svg>',
  failed: '<svg id="Group_1382" data-name="Group 1382" xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37"><path id="Path_1074" data-name="Path 1074" d="M0,0H37V37H0Z" fill="none"/><circle id="Ellipse_80" data-name="Ellipse 80" cx="13.74" cy="13.74" r="13.74" transform="translate(4.76 4.76)" fill="none" stroke="#f0252b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path id="Path_1075" data-name="Path 1075" d="M10,10l6.168,6.168m0-6.168L10,16.168" transform="translate(5.416 5.416)" fill="none" stroke="#f0252b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'
};