const { mainConfig, logoSvg, elements } = require('./variables');
const { $t } = require('./translate');

const feesHtml = function () {
  const html = `
    <div class="ottu__sdk-flex ottu__sdk-align-center card-only-display" style="display: flex; margin-top:15px; flex: 1 1 auto;">
      <span class="layout-flex-basis ottu__sdk-total ottu__sdk-width-20 ${elements.amountTitle}"> ${$t('Total Bill')}</span>
      <div class="layout-flex-basis" style="text-align:end; padding: 10px 10px; width:100%;">
        <span class="${elements.amount} ottu__sdk-amount"><span class="skeleton-loader"></span></span>
        <span class="${elements.currency} ottu__sdk-currency"><span class="skeleton-loader"></span></span>
      </div>
    </div>
    <div class="ottu__sdk-flex ottu__sdk-align-center card-only-display ${elements.feesBlock}" style="display: flex; margin-top:15px; flex: 1 1 auto;">
      <span class="${elements.feesLabel} layout-flex-basis ottu__sdk-total ottu__sdk-width-20">Fees</span>
      <div class="layout-flex-basis" style="text-align:end; padding: 10px 10px; width:100%;">
        <span class="${elements.fees} ottu__sdk-amount"><span class="skeleton-loader"></span></span>
        <span class="${elements.feesCurrency} ottu__sdk-currency"><span class="skeleton-loader"></span></span>
      </div>
    </div>
    <div class="ottu__sdk-flex ottu__sdk-align-center card-only-display ${elements.totalBlock}" style="display: flex; margin-top:15px; flex: 1 1 auto;">
      <span class="layout-flex-basis ottu__sdk-total ottu__sdk-width-20"> ${$t('Total')}</span>
      <div class="layout-flex-basis" style="text-align:end; padding: 10px 10px; width:100%;">
        <span class="${elements.total} ottu__sdk-amount"><span class="skeleton-loader" ></span></span>
        <span class="${elements.totalCurrency} ottu__sdk-currency"><span class="skeleton-loader"></span></span>
      </div>
    </div>`;
  return html;
};

const html = function () {
  const html = `
    <div id="${elements.sdkChild}" class="${mainConfig.lang}">
      <div class="ottu__sdk-container ottu__sdk-main ottu__sdk-position-relative">
        <div class="layout ottu__sdk-6 ottu__sdk-card-mobile" style="padding-top:30px;">
          <div class="layout-flex-basis layout-padding-right card-only-display">
            <div class="ottu__sdk-header">${logoSvg.cardNumber} ${$t('Payment Details')}</div>
            ${feesHtml()}
            <div id="${elements.savedCards}" class="ottu__sdk-flex layout d-none" style="padding-top:15px; border-top:2px solid #e0e0e0"></div>
          </div>
          <div id="${elements.blockMobileAppleButton}" class="layout2-apple-btn" style="width:100%;"></div>
          <div id="${elements.blockMobileGoogleButton}" class="layout2-apple-btn d-none" style="width:100%;">
          <span class="skeleton-loader" style="min-height: 48px;border-radius: 50px;width: 90%;margin: 0 auto;">
          </div>
          <div id="${elements.blockMethodsLoader}" class="card-only-display">
            <div><span class="skeleton-loader" style="min-height: 55px;margin-bottom: 5px;"></div>
            <div><span class="skeleton-loader" style="min-height: 55px;margin-bottom: 5px;"></div>
            <div><span class="skeleton-loader" style="min-height: 55px;margin-bottom: 5px;"></div>
          </div>
          <div id="${elements.blockMethods}" class="ottu__sdk-flex layout-flex-basis"></div>
          <div class="ottu__sdk-border ottu__sdk-mb card-only-display"></div>
        </div>
      </div>
      <div id="${elements.blockButtonsLoader}">
        <div><span class="skeleton-loader" style="min-height: 48px;border-radius: 50px;width: 50%;margin: 0 auto;"></div>
      </div>
      <div class="ottu__sdk-btns-parents ottu__sdk-main layoutButtons" style="align-self:center;">
        <div id="${elements.blockAppleButton}" class="layout-apple-btn layout-flex-basis d-none ottu__sdk-flex ottu__sdk-btns ottu__sdk-pay-button-block layoutButtons">
          <span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;">
        </div>
        <div id="${elements.blockGoogleButton}" class="layout-apple-btn layout-flex-basis d-none ottu__sdk-flex ottu__sdk-btns ottu__sdk-pay-button-block layoutButtons">
        <span class="skeleton-loader" style="min-height: 48px;border-radius: 50px;width: 90%;margin: 0 auto;">
        </div>
        <div id="${elements.blockButtons}" class="layout-flex-basis ottu__sdk-flex ottu__sdk-btns ottu__sdk-pay-button-block layoutButtons" disabled style="margin:12px 0px"></div>
      </div>
    </div>
    <div id="${elements.threeDS}"></div>
    ${htmlErorrModal()}
  `;
  return html;
};
exports.html = html;

const htmlErorrModal = function () {
  const html = `
    <div id="${elements.modal}" class="ottu__sdk-modal ${mainConfig.lang}"></div>
    <div id="${elements.modalOverlay}" class="${mainConfig.lang}"></div>
  `;
  return html;
};
exports.htmlErorrModal = htmlErorrModal;

const saveCardBlock = function () {
  const html = `
    <div id=${elements.savedCardsBlock} class="ottu__sdk-card-mobile ottu__sdk-card-mobile1" style="margin: 0 auto; width:100%">
      <h3 class="ottu__sdk-title">${$t('Saved Cards')}</h3>
      <h4 class="ottu__sdk-subtitle">${$t('List of all cards saved')}</h4>
      <div>{%content%}</div>
    </div>
  `;
  return html;
};
exports.saveCardBlock = saveCardBlock;

const mobileApplePayBlock = function () {
  const html = `
    <div id="${elements.blockAppleButton}" class="layout2-apple-btn layout-flex-basis d-none ottu__sdk-flex ottu__sdk-btns ottu__sdk-pay-button-block layout" style="width: 100%;">
      <span class="skeleton-loader" style="min-height: 48px;border-radius: 50px;width: 50%;margin: 0 auto;">
    </div>
  `;
  return html;
};
exports.mobileApplePayBlock = mobileApplePayBlock;

const enableCCV = function () {
  const html = `
    <div class="token{%key%} d-none" style="padding-right:30px;">
      <div class="ottu__sdk-input-div" style="display:flex; margin-inline:0px; margin-bottom:0px;">
        ${logoSvg.cvv}
        <input type="text" class="ottu__sdk-input-ccv" placeholder="${$t('Enter CVV')}" autocomplete="cc-name" data-brand="{%brand%}" />
      </div>
      <span class="ottu__sdk-error">
        <i></i>
      </span>
    </div>
  `;
  return html;
};

const saveCards = function (ccv_required) {
  const html = `
    <div class="ottu__sdk-position-relative ottu__sdk-radio-div">
      <input type="radio" name="token" id="token{%key%}" token="{%token%}" submit_url="{%url%}" class="ottu__sdk-payment {%radioClass%}" value="{%key%}" data-is_expired={%isExpired%} data-type="save" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}" data-currency_code_default="{%currency_code_default%}" data-ccv_required="{%ccv_required%}" data-is_preferred="{%is_preferred%}" />
      <label for="token{%key%}">
        <div class="ottu__sdk-flex" style="width:100%; justify-content:space-between;">
          <span class="ottu__sdk-flex" style="justify-content: start; margin-bottom:5px; min-width:150px;">
            <img src="{%logo%}" alt="{%alt%}" style="width: 33px; height:11px; margin-right:5px; align-self:center;" />
            <div class="ottu__sdk-flex ottu__sdk-flex-column" style="justify-content:center;">
              <span style="text-align:start;">
                <span class="ottu__sdk-card-num">{%number%}</span>
              </span>
              <span class="ottu__sdk-expires {%expiryClass%}">{%expiry%}</span>
            </div>
          </span>
          ${ccv_required ? enableCCV() : ''}
        </div>
      </label>
      <span ${ccv_required ? 'style="top:50%;"' : ''} class="${elements.saveCardDeleteButton}" delete_url="{%delete_url%}" data-number="{%number%}" >${logoSvg.delete}</span>
    </div>
  `;
  return html;
};
exports.saveCards = saveCards;

const applePayNotSupportedHtml = function () {
  return `
    <div id="${elements.block_not_supported}" class="d-flex not-supported">This device doesn't support Apple Pay</div>
  `;
};
exports.applePayNotSupportedHtml = applePayNotSupportedHtml;

const paymentMethodBlock = function () {
  const html = `
    <div style="width:100%">
      <h3 class="ottu__sdk-title">${$t('Payment Methods')}</h3>
      <h4 class="ottu__sdk-subtitle">${$t('List of all gateways we support')}</h4>
      <div class="ottu__sdk-accordion">{%content%}</div>
    </div>
  `;
  return html;
};
exports.paymentMethodBlock = paymentMethodBlock;

const paymentMethod = function () {
  const html = `
    <div id="method{%key%}" class="${elements.accordionItem}" name="{%flow%}" {%flow%}="{%code%}" can-save-card="{%savecard%}" submit_url="{%submit_url%}" redirect_url="{%redirect_url%}" payment_url="{%payment_url%}" cancel_url="{%cancel_url%}" data-type="method" data-description="{%description%}" data-fee="{%fee%}" data-currency_code="{%currency_code%}" data-amount="{%amount%}" data-currency_code_default="{%currency_code_default%}">
      <div class="${elements.accordionItemHeader}">
        <div class="ottu__sdk-partial-width">
          <img src="{%logo%}" alt="{%name%}" style="width:48px; height:32px"/>
        </div>
        <div class="ottu__sdk-flex" style="width:100%; justify-content:space-between;">
        <span class="ottu__sdk-flex ottu__sdk-flex-column">
          <span class="ottu__sdk-payment-name" style="margin:auto 0;padding-left:10px;">{%name%}</span>
        </span>
        {%saveTokenCheckbox%}
        </div>
      </div>
    </div>
  `;
  return html;
};
exports.paymentMethod = paymentMethod;


const buttonPay = function () {
  const html = `
    <button type="button" id="${elements.payButton}" class="ottu__sdk-green pay-button ottu__sdk-disabled card-only-display">
      <span>${$t('Accept & Pay')}</span>
      <div>${logoSvg.loading}</div>
    </button>
  `;
  return html;
};
exports.buttonPay = buttonPay;

const cancelPay = function () {
  const html = `
    <a href="javascript:void(0)" id="${elements.payCancel}" class="ottu__sdk-pay-cancel">
      <span>${$t('Cancel')}</span>
    </a>
  `;
  return html;
};
exports.cancelPay = cancelPay;

const buttonApple = function (isWalletActive) {
  const html = isWalletActive ? 
    `<div id="${elements.applePayButton}" class="ottu__sdk-black-div apple-pay-button ottu__sdk-apple-pay-button-type"></div>`:
    `<div id="${elements.applePayButton}" class="ottu__sdk-black-div apple-pay-button ottu__sdk-apple-pay-button-type"></div>
    <span class="${elements.applePayUnavailableText}">${$t('Apple Pay unavailable. Please update your device, setup Wallet, and add an active card and reload the page.')}</span>`;
  return html;
};
exports.buttonApple = buttonApple;

const cardRemoveModal = function () {
  const html = `
    <div class="ottu__sdk-modal-header">
      <div class="ottu__sdk-modal-title closeBtn">${$t('Delete')}</div>
      <button class="ottu__sdk-close-btn closeBtn">${logoSvg.modalX}</button>
    </div>
    <div class="ottu__sdk-modal-body">
      <span>${$t('Remove')} : {%number%}</span>
      <p>
        ${$t('Click Confirm if you don\'t want this payment method to be displayed in your payment options')}
      </p>
      <div class="ottu__sdk-modal-btns">
        <button class="closeBtn">${$t('Cancel')}</button>
        <button class="delete">${$t('Delete')}</button>
      </div>
    </div>
  `;
  return html;
};
exports.cardRemoveModal = cardRemoveModal;

const failedModal = function () {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-red ottu__sdk-paiment-failed">
        ${logoSvg.failed}
        ${$t('Payment Failed')}
      </span>
      <p>{%error%}</p>
      <div class="ottu__sdk-modal-data">{%response%}</div>
      <div class="ottu__sdk-modal-btns2">
        <button class="closeBtn">${$t('OK')}</button>
      </div>
    </div>
  `;
  return html;
};
exports.failedModal = failedModal;

const stcPayModal = function (canSaveCard) {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-close-btn">
        ${logoSvg.modalX}
      </span>
      <span>
        ${$t('Confirm Phone Number')}
      </span>
      <div id="${elements.stcPayMobileNumberBlock}" style="display:flex; flex-direction:column; align-items:center; ${canSaveCard === 'true' ? 'margin-bottom:-10px;' : 'margin-bottom:15px;'}">
        <div class="ottu__sdk-input-div" style="width:280px;margin:10px auto; background: #ddd;">
          <input id="${elements.stcPayMobileNumber}" class="ottu__sdk-input-stc" type="text" value="{%mobileNo%}" placeholder="${$t('Enter Mobile Number ...')}"/>
        </div>
        <span class="ottu__sdk-error" style="text-align: center;">
          <i></i>
        </span>
      </div>
      ${ canSaveCard === 'true' ? saveCardCheckbox('stc') : ''}
      <div class="ottu__sdk-modal-btns2">
        <button class="sendOtpBtn">${$t('Send OTP')}</button>
      </div>
      <div class="ottu__sdk-stc-loader">${logoSvg.loading}</div>
    </div>
  `;
  return html;
};
exports.stcPayModal = stcPayModal;

const stcPayOTPModal = function () {
  const html = `
    <div class="ottu__sdk-modal-body">
      <span class="ottu__sdk-back-btn">
        ${logoSvg.back}
      </span>
      <span>
        ${$t('Enter OTP')}
      </span>
      <div id="${elements.stcOTPBlock}" style="display:flex; flex-direction:column; align-items:center;">
        <div class="ottu__sdk-input-div" style="width:200px; margin:20px auto; background: #ddd;">
          <input id="${elements.stcPayOTP}" type="text" class="ottu__sdk-input-stc" placeholder="${$t('Enter OTP ...')}"/>
        </div>
        <span class="ottu__sdk-error" style="text-align: center;">
          <i></i>
        </span>
      </div>
      <div class="ottu__sdk-modal-btns2">
      <button class="stcPayBtn">${$t('Pay')}</button>
      </div>
      <div class="ottu__sdk-stc-loader">${logoSvg.loading}</div>
    </div>
  `;
  return html;
};
exports.stcPayOTPModal = stcPayOTPModal;

const iframeLoader = function () {
  const html = `
    <div id="${elements.blockIframeLoader}" style="max-width: 500px;width: 100%;margin: 0 auto;">
      <div style="padding: 0 50px 0 50px;"><span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;width: 200px;margin: 10px auto;"></div>
      <div style="padding: 0 50px 0 50px;"><span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;"></div>
      <div style="padding: 0 50px 0 50px;"><span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;"></div>
      <div style="padding: 0 50px 0 50px;"><span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;"></div>
      <div style="padding: 0 50px 0 50px;"><span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;"></div>
      <div style="padding: 0 50px 0 50px;"><span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;"></div>
      <div style="padding: 0 50px 0 50px;"><span class="skeleton-loader" style="min-height: 25px;margin-bottom: 5px;"></div>
    </div>
  `;
  return html;
};
exports.iframeLoader = iframeLoader;

const saveCardCheckbox = function (type) {
  const html = `
    <div id="${elements.cardSaveBlcok}" class="ottu__sdk-pointer" style="padding:10px;margin-inline-end: 15px;${type == 'stc' ? 'margin-inline-start: 3%;' : ''}">
      <input
        type="checkbox"
        name="save-card{%key%}"
        id="save-card{%key%}"
        class="ottu__sdk-checkbox"
      />
      <label for="save-card{%key%}">${$t('Click To Save Your Card')}</label>
    </div>`;
  return html;
};
exports.saveCardCheckbox = saveCardCheckbox;