const { mainConfig } = require('./variables');
const curScriptElement = document.currentScript;
const { Selector } = require('./selector');


function baseRequest(){
  return Object.assign({}, {
    apiVersion:  mainConfig.google_apiVersion ? mainConfig.google_apiVersion : 2,
    apiVersionMinor: mainConfig.google_apiVersionMinor ? mainConfig.google_apiVersionMinor : 0
  });
}

const allowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'];
const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

function tokenizationSpecification (){
  return Object.assign({}, {
    type : mainConfig.google_tokenizationSpecificationType ? mainConfig.google_tokenizationSpecificationType : 'PAYMENT_GATEWAY',
    parameters : {
      'gateway' : mainConfig.google_gateway ? mainConfig.google_gateway : undefined,
      'gatewayMerchantId' : mainConfig.google_gateway_merchant_id ? mainConfig.google_gateway_merchant_id : undefined
    }
  }
  );
}

function baseCardPaymentMethod() {
  return Object.assign({}, {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: mainConfig.google_allowedCardAuthMethods ? mainConfig.google_allowedCardAuthMethods : allowedCardAuthMethods,
      allowedCardNetworks: mainConfig.google_allowedCardNetworks ? mainConfig.google_allowedCardNetworks : allowedCardNetworks
    }
  });
}

function cardPaymentMethod(){
  return Object.assign({}, baseCardPaymentMethod(),
    {
      tokenizationSpecification: tokenizationSpecification()
    }
  );
} 

let paymentsClient = mainConfig.google_paymentsClient ? mainConfig.google_paymentsClient : null;

function getGoogleIsReadyToPayRequest() {
  return Object.assign(
    {}, baseRequest(),
    {
      allowedPaymentMethods: [baseCardPaymentMethod()]
    }
  );
}

function getGooglePaymentDataRequest() {
  const paymentDataRequest = Object.assign({}, baseRequest());
  paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod()];
  paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
  paymentDataRequest.merchantInfo = {
    merchantId: mainConfig.google_merchant_id ? mainConfig.google_merchant_id : undefined,
    merchantName: mainConfig.google_merchant_name ? mainConfig.google_merchant_name : undefined,
  };
  paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION'];

  return paymentDataRequest;
}

function getGooglePaymentsClient() {
  if ( paymentsClient === null ) {
    paymentsClient = new google.payments.api.PaymentsClient({
      environment: mainConfig.google_environment ? mainConfig.google_environment : undefined ,
      paymentDataCallbacks: {
        onPaymentAuthorized: onPaymentAuthorized
      }
    });
  }
  return paymentsClient;
}

function onPaymentAuthorized(paymentData) {
  return new Promise(function(resolve){
    processPayment(paymentData, mainConfig  ,function (outcome) {
      if (outcome.status === '3DS') {
        resolve({transactionState: 'SUCCESS'});
        Selector.generate3DSPopUp(outcome);
      } else if (outcome.approved) {
        window[curScriptElement.dataset.success](outcome.callback_payload);
        resolve({transactionState: 'SUCCESS'});
      } else if (!outcome.approved) {
        if(outcome.callback_payload && outcome.callback_payload.status == 'canceled'){
          resolve({
            transactionState: 'ERROR',
            error: {
              intent: 'PAYMENT_AUTHORIZATION', 
              message: outcome.callback_payload.message || outcome.message,
              reason: 'OTHER_ERROR'
            }
          });
          window[curScriptElement.dataset.cancel](outcome.callback_payload);
        } else if(outcome.callback_payload && outcome.callback_payload.status == 'error'){
          resolve({
            transactionState: 'ERROR',
            error: {
              intent: 'PAYMENT_AUTHORIZATION', 
              message: outcome.callback_payload.message || outcome.message,
              reason: 'OTHER_ERROR'
            }
          });
          window[curScriptElement.dataset.error](outcome.callback_payload);
        } else{
          resolve({
            transactionState: 'ERROR',
            error: {
              intent: 'PAYMENT_AUTHORIZATION', 
              message: outcome.message,
              reason: 'OTHER_ERROR'
            }
          }); 
          window[curScriptElement.dataset.error]({ status: 'error', ...outcome, });
        }
      } 
    });
  });
}

async function onGooglePayLoaded(documentCheckout) {
  const paymentsClient = getGooglePaymentsClient();
  return await paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
    .then(async function(response) {
      if (response.result) {
        return await addGooglePayButton(documentCheckout);
      }
    })
    .catch(async function(err) {
      window[curScriptElement.dataset.error]({ status: 'error', message: err.message, ... err});
    });
}

async function addGooglePayButton(documentCheckout) {
  const paymentsClient = getGooglePaymentsClient();
  try {
    const button = paymentsClient.createButton({
      buttonType: mainConfig.google_buttonType ? mainConfig.google_buttonType : undefined,
      buttonLocale: mainConfig.google_buttonLocale ? mainConfig.google_buttonLocale : undefined,
      onClick: onGooglePaymentButtonClicked,
      buttonRootNode: documentCheckout,
    });
    return button;
  } catch (err) {
    window[curScriptElement.dataset.error]({ status: 'error', message: err.message, ...err });
  }
  
}

function getGoogleTransactionInfo() {
  return {
    countryCode: mainConfig.google_country_code ? mainConfig.google_country_code : undefined,
    currencyCode: mainConfig.google_currency_code ?  mainConfig.google_currency_code : undefined,
    totalPriceStatus: mainConfig.google_totalPriceStatus ? mainConfig.google_totalPriceStatus :'FINAL',
    totalPrice: mainConfig.google_total_price ? `${parseFloat(mainConfig.google_total_price)}` : undefined,
    totalPriceLabel: mainConfig.google_totalPriceLabel ? mainConfig.google_totalPriceLabel : 'Total'
  };
}

function onGooglePaymentButtonClicked() {
  const paymentDataRequest = getGooglePaymentDataRequest();
  paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
  const paymentsClient = getGooglePaymentsClient();

  paymentsClient.loadPaymentData(paymentDataRequest)
    .catch(err => {
      if(err.statusCode==='CANCELED'){
        window[curScriptElement.dataset.cancel]({ status: 'cancel', message: err.message , ...err});
      }
      else
        window[curScriptElement.dataset.error]({ status: 'error', message: err.statusMessage , statusCode: err.statusCode });
    });  
}

function processPayment (paymentData , mainConfig , callback ) {
  
  const body = {
    google_pay_payload: {
      apiVersion: paymentData.apiVersion,
      apiVersionMinor: paymentData.apiVersionMinor,
      paymentMethodData: paymentData.paymentMethodData
    },
    currency_code: mainConfig.google_currency_code,
    amount: mainConfig.google_total_price,
    session_id: mainConfig.session_id,
    code: mainConfig.google_code,
  };

  fetch( mainConfig.google_payment_url , {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Api-Key ${mainConfig.apiKey}`,
    },
    body: JSON.stringify(body)
  })
    .then(async (response) =>{
      if (response.ok || response.approved) {
        return await response.json();
      }
      else{
        throw new Error(JSON.stringify(await response.json()));
      }
    })
    .then((data) => {
      callback(data);
    })
    .catch((err) => {
      callback(JSON.parse(err.message));
    });

}
exports.onGooglePayLoaded = onGooglePayLoaded;
exports.processPayment = processPayment;