const {
  setConfigs,
  getHtml,
  getSaveCardsHtml,
  getPaymentMethodsHtml,
  getCardRemoveModalHtml,
  getFailedModalHtml,
  getButtonsHtml,
  checkSaveCardsExist,
  checkRender,
  getHtmlErorrModal,
  setEventListenerForRadioButtons,
  mobileApplePayHtml,
  getApplePayNotSupportedHtml,
  checkFormsofPayments,
  replaceSdk,
} = require('./util');
  
const { getPaymentDetails } = require('./http');
const { mainConfig, elements } = require('./variables');
const { _applePayAvailable, _startApplePaySession, _openAppleWalletApp, _isWalletActive } = require('./apple');
const { Selector } = require('./selector');
const { onGooglePayLoaded } = require('./googlePay');
  
Checkout = typeof Checkout === 'undefined' ? {} : Checkout;
  
const curScriptElement = document.currentScript;
Selector.setCurrentScript(curScriptElement);
  
const setEvents = (applePay, isWalletActive) => {
  const payMethods = documentCheckout.querySelectorAll('.'+elements.accordionItem);
  const cards = documentCheckout.getElementById(elements.savedCardsBlock);
  let isPrefferedCard = false;
  if (cards) {
    const savedCards = documentCheckout.querySelectorAll('.'+elements.accordionItemRadio);
    savedCards.forEach( element => {
      if (!(element.getAttribute('data-is_expired') === 'true') && (element.getAttribute('data-is_preferred') === 'true')) {
        element.checked = true;
        Selector.selectePayMethod(element);
        isPrefferedCard = true;
      }
    });

    if(!isPrefferedCard){
      if(savedCards[0] && (savedCards[0].getAttribute('data-is_expired') == 'false')){
        savedCards[0].checked = true;
        Selector.selectePayMethod(savedCards[0]);
      }
    }
  }

  if (payMethods.length == 1 && !cards) {
    Selector.selectePayMethod(payMethods[0]);
  }

  payMethods.forEach(element => {
    element.addEventListener('click', function() {
      Selector.selectePayMethod(this);
    }, true);
  });

  documentCheckout.querySelectorAll('.'+elements.accordionItemRadio).forEach(element => {
    if (!(element.getAttribute('data-is_expired') === 'true')){
      element.addEventListener('click', function() {
        Selector.selectePayMethod(this);
      }, true);
    }
    else{
      element.disabled = true;
      element.nextElementSibling.classList.add('ottu__sdk-disabled');
    }
  });

  if (documentCheckout.getElementById(elements.payButton)) {
    documentCheckout.getElementById(elements.payButton).addEventListener('click', function() {
      Selector.payButtonClick();
    }, true);
  }
  
  documentCheckout.querySelectorAll('.'+elements.saveCardDeleteButton).forEach(element => {
    element.addEventListener('click', function() {
      const number = this.attributes['data-number'].value;
      const delete_url = this.attributes.delete_url.value;
      const modal = documentCheckout.getElementById(elements.modal);
      Selector.showModal(getCardRemoveModalHtml(number));
      const radioDiv = this.closest('.ottu__sdk-radio-div');
      modal.querySelector('.delete').addEventListener('click', function() {
        Selector._deleteToken(delete_url, radioDiv);
        documentCheckout.getElementById(elements.payButton).classList.add('ottu__sdk-disabled');
      }, true);
    }, true);
  });
  const cancel = documentCheckout.getElementById(elements.payCancel);
  if (cancel) {
    cancel.addEventListener('click', function() {
      Selector._cancelPay();
    }, true);
  }
 
  if (applePay) {
    const appleBlock = documentCheckout.querySelectorAll(`#${elements.blockAppleButton}`);
    appleBlock.forEach(element => {
      element.querySelector('#'+elements.applePayButton).addEventListener('click',  () => {
        if (isWalletActive) {
          _startApplePaySession();
        } else {
          _openAppleWalletApp(mainConfig.apple_merchant_id);
        }
      });
    });
  }
};

const injectCSS = (css) => {
  let sdk_child = documentCheckout.getElementById(elements.sdkChild);
  sdk_child.appendChild(css);
  return css;
};

const renderHtml = (overriddenCss) => {
  const css = '<link href="'+process.env.SERVER_URL+'/v2/css/checkout.css" type="text/css" rel="stylesheet" media="screen,print"></link>';
  documentCheckout = document.getElementById(mainConfig.selector).attachShadow({ mode: 'closed' });

  Selector.setSelector(documentCheckout);
  
  documentCheckout.innerHTML = css + getHtml();
  
  let sheet = document.createElement('style');
  sheet.innerHTML = overriddenCss;
  injectCSS(sheet);
};

let documentCheckout;
function init (configs, reload) {  
  if (!document.getElementById(configs.selector)) {
    return;
  } else if (reload) {
    replaceSdk(mainConfig);
  }

  if(!document.querySelector('meta[content=\'width=device-width, initial-scale=1.0\']')) {
    document.head.innerHTML += '<meta content="width=device-width, initial-scale=1.0" name="viewport">';
  }

  if (document.location.protocol === 'https:') {
    if (!configs.formsOfPayment) {
      configs = {
        ...configs,
        formsOfPayment: ['applePay', 'tokenPay', 'ottuPG', 'redirect', 'googlePay', 'stcPay'],
      };
    }
    const valid = setConfigs(configs, false , false);
    if (!valid) {
      Selector.callBackError({
        status: 'error',
        message: 'incorrect configs'
      });
      return false;
    }
    
    if (checkRender(mainConfig)) {
      renderHtml(mainConfig.css);
      if (!(checkFormsofPayments('tokenPay') || checkFormsofPayments('ottuPG') || checkFormsofPayments('redirect') || checkFormsofPayments('googlePay') || checkFormsofPayments('stcPay'))) {
        Selector.renderOnlyApplePay();
        documentCheckout = Selector.documentCheckout;
      }
     
      getPaymentDetails(mainConfig.merchant_id, mainConfig.session_id ,mainConfig.apiKey)
        .then(async data => {
          let html = '';
          let applePay = false;
          let googlePay = false;
          let cancel = false;
          const saveCard = checkSaveCardsExist(data);
          if (saveCard && checkFormsofPayments('tokenPay')) {
            const saveCardsHtml = getSaveCardsHtml(data.cards, data.currency_code, data.payment_methods);
            const saveCards = documentCheckout.querySelector('#'+elements.savedCards);
            saveCards.classList.remove('d-none');
            saveCards.innerHTML = saveCardsHtml;
            setEventListenerForRadioButtons(saveCards.querySelectorAll('.ottu__sdk-input-ccv'));
          }
          documentCheckout.getElementById(elements.blockMobileAppleButton).innerHTML = mobileApplePayHtml();
          if (checkFormsofPayments('redirect') || checkFormsofPayments('ottuPG') || checkFormsofPayments('stcPay')) {
            window.redirectSubmitUrl = data.submit_url;
            documentCheckout.getElementById(elements.blockMethodsLoader).remove();
            html+= getPaymentMethodsHtml(data.payment_methods, data.can_save_card, data.currency_code);

            if(!getPaymentMethodsHtml(data.payment_methods, data.can_save_card, data.currency_code)){
              documentCheckout.querySelectorAll('.card-only-display').forEach(element => {
                element.style.display = 'none';
              });
              documentCheckout.querySelectorAll('.ottu__sdk-card-mobile').forEach(element => {
                element.style.padding = '0px';
                element.classList.remove('ottu__sdk-card-mobile');  
              });
              documentCheckout.getElementById(elements.blockButtons).classList.add('d-none');
            }

            documentCheckout.getElementById(elements.blockMethods).innerHTML = html;
          }

          if (_applePayAvailable() && data.apple_pay_available && checkFormsofPayments('applePay')) {
            applePay = true;
            setConfigs(data.apple_pay_config, true, false);
          }
        
          if (data.google_pay_available && checkFormsofPayments('googlePay')) {
            googlePay = true ;
            setConfigs(data.google_pay_config, false , true);
            setConfigs(configs.googlePayInit,false,true);
            
            if(mainConfig.google_buttonStyles){
              let overriddenStyles = mainConfig.google_buttonStyles;   
              let sheet = document.createElement('style');
              sheet.innerHTML = `.google-pay-button {width:${ overriddenStyles['width'] ? overriddenStyles['width'] : ''}; margin-top:${ overriddenStyles['margin-top'] ? overriddenStyles['margin-top'] : ''}; margin-bottom:${ overriddenStyles['margin-bottom'] ? overriddenStyles['margin-bottom'] : ''};}`;
              injectCSS(sheet);
            }
          }

          setConfigs({...mainConfig, public_key_url: data.public_key_url, customer_phone: data.customer_phone}, false,false);
          documentCheckout.querySelectorAll(`.${elements.amount}`).forEach(element => {
            element.innerHTML = data.amount;
          });
          documentCheckout.querySelectorAll(`.${elements.currency}`).forEach(element => {
            element.innerHTML = data.currency_code;
          });
          documentCheckout.getElementById(elements.blockButtonsLoader).remove();

          if (data.payment_methods && data.payment_methods.length == 0 || 
            !(checkFormsofPayments('tokenPay') || checkFormsofPayments('redirect') || checkFormsofPayments('ottuPG') || checkFormsofPayments('googlePay') || checkFormsofPayments('stcPay'))) {
            if (applePay && checkFormsofPayments('applePay')) {
              setConfigs(data.apple_pay_config, true, false);
              documentCheckout.getElementById(elements.blockButtons).classList.add('d-none');
            } else {
              documentCheckout.getElementById(elements.blockButtons).innerHTML = getApplePayNotSupportedHtml();
            }
          } else {
            documentCheckout.getElementById(elements.blockButtons).innerHTML = getButtonsHtml(cancel);
          }
          
          if (checkFormsofPayments('applePay')) {
            var isWalletActive = mainConfig.apple_merchant_id ?  await _isWalletActive(): true;
            Selector.setSelector(documentCheckout);
            Selector.showAppleButton(applePay, isWalletActive);
            Selector.changeButtonLayout(applePay,googlePay);  
            documentCheckout = Selector.documentCheckout;
          }

          if (googlePay && checkFormsofPayments('googlePay')) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://pay.google.com/gp/p/js/pay.js';
            script.onload = async function() {
              var button = await onGooglePayLoaded(documentCheckout);
              var mobileButton = await onGooglePayLoaded(documentCheckout);
              if(button && mobileButton){
                Selector.showGoogleButton(googlePay, button.firstChild,mobileButton.firstChild);
                Selector.changeButtonLayout(applePay,googlePay);  
              } 
              documentCheckout = Selector.documentCheckout;
            };
            document.head.appendChild(script);
          }

          setEvents(applePay, isWalletActive);
        })
        .catch(err => {
          Selector.errorResponse(err);
        });
    }
  } else {
    documentCheckout = document.getElementById(configs.selector).attachShadow({ mode: 'closed' });
    Selector.setSelector(documentCheckout);
    const css = '<link href="'+process.env.SERVER_URL+'/v2/css/checkout.css" type="text/css" rel="stylesheet" media="screen,print"></link>';
    documentCheckout.innerHTML = css + getHtmlErorrModal();
    Selector.showModal(getFailedModalHtml('Website doesn\'t run on non https pages'));
  }
}
  
function reload () {
  init(mainConfig, true);
}
  
function showErrorPopup(message , response) {
  if(!response){
    Selector.showModal(getFailedModalHtml(message || 'Something Went Wrong.'));
  } else {
    Selector.showModal(getFailedModalHtml(message , response));
  }
}
  
exports.pay = Selector.pay;
exports.init = init;
exports.reload = reload;
exports.showErrorPopup = showErrorPopup;