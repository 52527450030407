const { mainConfig, cardsSvg, elements, method } = require('./variables');
const {
  html,
  htmlErorrModal,
  saveCardBlock,
  saveCards,
  paymentMethodBlock,
  paymentMethod,
  buttonPay,
  cancelPay,
  buttonApple,
  cardRemoveModal,
  failedModal,
  iframeLoader,
  saveCardCheckbox,
  mobileApplePayBlock,
  applePayNotSupportedHtml,
  stcPayModal,
  stcPayOTPModal,
} = require('./html');
const { $t } = require('./translate');

const setConfigs = function (config, applePay,googlePay) {
  if (config) {
    if (applePay) {
      // applePay
      if(typeof config.merchant_id != 'undefined'){ mainConfig.apple_merchant_id = config.merchant_id; }
      if(typeof config.amount != 'undefined'){ mainConfig.amount = config.amount; }
      if(typeof config.code != 'undefined'){ mainConfig.code = config.code; }
      if(typeof config.country_code != 'undefined'){ mainConfig.country_code = config.country_code; }
      if(typeof config.currency_code != 'undefined'){ mainConfig.currency_code = config.currency_code; }
      if(typeof config.fee != 'undefined'){ mainConfig.fee = config.fee; }
      if(typeof config.payment_url != 'undefined'){ mainConfig.payment_url = config.payment_url; }
      if(typeof config.shop_name != 'undefined'){ mainConfig.shop_name = config.shop_name; }
      if(typeof config.validation_url != 'undefined'){ mainConfig.validation_url = config.validation_url; }
      if(typeof config.applePayConfig != 'undefined'){ mainConfig.applePayConfig = config.applePayConfig; }
    }
    else if(googlePay){
      if(typeof config.merchant_id != 'undefined'){ mainConfig.google_merchant_id = config.merchant_id; }
      if(typeof config.merchant_name != 'undefined'){ mainConfig.google_merchant_name = config.merchant_name; }
      if(typeof config.gateway != 'undefined'){ mainConfig.google_gateway = config.gateway; }
      if(typeof config.gateway_merchant_id != 'undefined'){ mainConfig.google_gateway_merchant_id = config.gateway_merchant_id; }
      if(typeof config.fee != 'undefined'){ mainConfig.google_fee= config.fee; }
      if(typeof config.code != 'undefined'){ mainConfig.google_code = config.code; }
      if(typeof config.country_code != 'undefined'){ mainConfig.google_country_code = config.country_code; }
      if(typeof config.currency_code != 'undefined'){ mainConfig.google_currency_code = config.currency_code; }
      if(typeof config.environment != 'undefined'){ mainConfig.google_environment = config.environment; }
      if(typeof config.total_price != 'undefined'){ mainConfig.google_total_price = config.total_price; }
      if(typeof config.payment_url != 'undefined'){ mainConfig.google_payment_url = config.payment_url; }
      //Overriden Configs
      if(typeof config.apiVersion != 'undefined'){ mainConfig.google_apiVersion = config.apiVersion; }
      if(typeof config.apiVersionMinor != 'undefined'){ mainConfig.google_apiVersionMinor = config.apiVersionMinor; }
      if(typeof config.allowedCardNetworks != 'undefined'){ mainConfig.google_allowedCardNetworks = config.allowedCardNetworks; }
      if(typeof config.allowedCardAuthMethods != 'undefined'){ mainConfig.google_allowedCardAuthMethods = config.allowedCardAuthMethods; }
      if(typeof config.tokenizationSpecificationType != 'undefined'){ mainConfig.google_tokenizationSpecificationType = config.tokenizationSpecificationType; }
      if(typeof config.baseCardPaymentMethodType != 'undefined'){ mainConfig.google_baseCardPaymentMethodType = config.baseCardPaymentMethodType; }
      if(typeof config.paymentsClient != 'undefined'){ mainConfig.google_paymentsClient = config.paymentsClient; }
      if(typeof config.totalPriceStatus != 'undefined'){ mainConfig.google_totalPriceStatus = config.totalPriceStatus; }
      if(typeof config.totalPriceLabel != 'undefined'){ mainConfig.google_totalPriceLabel = config.totalPriceLabel; }
      //Overriden Button Configs
      if(typeof config.buttonType != 'undefined'){ mainConfig.google_buttonType = config.buttonType; }
      if(typeof config.buttonLocale != 'undefined'){ mainConfig.google_buttonLocale = config.buttonLocale; }
      if(typeof config.buttonStyles != 'undefined'){ mainConfig.google_buttonStyles = config.buttonStyles; }
    } 
    else {
      //sdk
      if(typeof config.formsOfPayment != 'undefined' && config.formsOfPayment.length > 0){
        mainConfig.formsOfPayment = config.formsOfPayment;
      }
      if(typeof config.lang != 'undefined'){ mainConfig.lang = config.lang; }
      if(typeof config.session_id != 'undefined'){ mainConfig.session_id = config.session_id; }
      if(typeof config.merchant_id != 'undefined'){ mainConfig.merchant_id = config.merchant_id; }
      if(typeof config.selector != 'undefined'){ mainConfig.selector = config.selector; }
      if(typeof config.apiKey != 'undefined'){ mainConfig.apiKey = config.apiKey; }
      if(typeof config.render != 'undefined'){ mainConfig.render = config.render; }
      if(typeof config.css != 'undefined'){ mainConfig.css = config.css; }
      if(typeof config.customer_phone != 'undefined'){ mainConfig.customer_phone = config.customer_phone; }
    }
  }
  
  if (mainConfig.selector) {
    return true;
  } else {
    return false;
  }
};

const ccvValidation = (cardCcv) => {
  const data = {};
  const errors = {};
  let error = false;

  if (cardCcv) {
    data.cvv = cardCcv;
  } else {
    errors.tokenCardCcvBlock = $t('This field is required');
    error = true;
  }
    
  return {
    data: data,
    errors: errors,
    error: error
  };
};
const phoneNumberSetSpace = (phoneNumber) => {
  if (phoneNumber.length > 3 && phoneNumber.length <= 6) {
    return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3);
  } else if (phoneNumber.length > 6) {
    return phoneNumber.slice(0, 3) + ' ' +phoneNumber.slice(3, 6) + ' ' +phoneNumber.slice(6);
  } else {
    return phoneNumber;
  }
};

const getSaveCardsHtml = (cards, currencyCodeDefault, paymentMethods) => {
  let html = '';
  if(cards && cards.length) {
    for (let key = 0; key < cards.length; key++) {
      const card = cards[key];
      let currencyCode = currencyCodeDefault;
      let amount = '';
      let fee = '';
      let description = '';
      if (paymentMethods) {
        const m = paymentMethods.find(({ code }) => code == card.pg_code );
        if (m) {
          currencyCode = m.currency_code;
          fee = m.fee;
          amount = m.amount;
          description= m.fee_description;
        }
      }
      let expiry = '';
      if (card.is_expired) {
        if (card.expiry_month && card.expiry_year) {
          expiry = $t('Expired on') + ' ' + card.expiry_month + '/' + card.expiry_year;
        } else {
          expiry = $t('Expired');
        }
      } else {
        expiry = $t('Expires on') + ' ' + card.expiry_month + '/' + card.expiry_year;
      }


      const expiryClass = card.is_expired ? 'ottu__sdk-red':'';

      html+= saveCards(card.cvv_required)
        .replace(/{%key%}/g, key)
        .replace(/{%logo%}/g, cardsSvg[card.brand])
        .replace(/{%brand%}/g, card.brand)
        .replace(/{%alt%}/g, card.brand)
        .replace(/{%url%}/g, card.submit_url)
        .replace(/{%token%}/g, card.token)
        .replace(/{%number%}/g, card.number)
        .replace(/{%expiry%}/g, expiry)
        .replace(/{%expiryClass%}/g, expiryClass)
        .replace(/{%isExpired%}/g, card.is_expired)
        .replace(/{%radioClass%}/g, elements.accordionItemRadio)
        .replace(/{%ccv_required%}/g, card.cvv_required)
        .replace(/{%is_preferred%}/g, card.is_preferred)
        .replace(/{%delete_url%}/g, card.delete_url)
        .replace(/{%fee%}/g,fee )
        .replace(/{%description%}/g, description || '')
        .replace(/{%currency_code%}/g, currencyCode)
        .replace(/{%amount%}/g, amount)
        .replace(/{%currency_code_default%}/g, currencyCodeDefault);
    }
  }
  
  const saveCard = saveCardBlock().replace(/{%content%}/g, html);
  return saveCard;
};

const mobileApplePayHtml = () => {
  let html = '';
  html += mobileApplePayBlock();
  return html;
};

const getPaymentMethodsHtml = (methods, saveCard, currency_code) => {
  let html = '';
  if (methods) {
    for (let key = 0; key < methods.length; key++) {
      const method = methods[key];
      let canSaveCard;
      if(typeof saveCard == 'boolean'){
        canSaveCard = saveCard;
      }
      else if(typeof saveCard == 'undefined'){
        canSaveCard = method.can_save_card ? method.can_save_card : false;
      }

      if ((method.flow === 'ottu_pg' &&  checkFormsofPayments('ottuPG')) || (method.flow === 'stc_pay' && checkFormsofPayments('stcPay')) || (method.flow === 'redirect' && checkFormsofPayments('redirect'))) {
        html+= paymentMethod()
          .replace(/{%saveTokenCheckbox%}/g, method.flow === 'redirect' && canSaveCard && !window.redirectSubmitUrl ?  saveCardCheckbox() : '')
          .replace(/{%savecard%}/g, method.can_save_card)
          .replace(/{%key%}/g, key)
          .replace(/{%logo%}/g, method.icon)
          .replace(/{%submit_url%}/g, method.submit_url)
          .replace(/{%redirect_url%}/g, method.redirect_url)
          .replace(/{%payment_url%}/g, method.payment_url)
          .replace(/{%cancel_url%}/g, '')
          .replace(/{%flow%}/g, method.flow)
          .replace(/{%code%}/g, method.code)
          .replace(/{%name%}/g, method.name)
          .replace(/{%fee%}/g, method.fee)
          .replace(/{%description%}/g, method.fee_description || '')
          .replace(/{%currency_code%}/g, method.currency_code)
          .replace(/{%amount%}/g, method.amount)
          .replace(/{%currency_code_default%}/g, currency_code);
      } 
      
    }
  }
  const paymentMethodhtml = html ? paymentMethodBlock().replace(/{%content%}/g, html) : html;
  return paymentMethodhtml;
};
const getCardRemoveModalHtml = (number) => {
  return cardRemoveModal().replace(/{%number%}/g, number);
};

const getFailedModalHtml = (error, response) => {
  let html = '';
  if(response){
    for (const key in response){
      if(Object.prototype.hasOwnProperty.call(response,key)){
        html += `<p>${key} : ${response[key]}</p>`;
      }
    }
    return failedModal()
      .replace(/{%error%}/g, error)
      .replace(/{%response%}/g,html);
  } else {
    return failedModal()
      .replace(/{%error%}/g, error)
      .replace(/{%response%}/g,'');
  }
};

const getStcPayModal = (mobileNo, canSaveCard) => {
  if ( mobileNo) {
    mobileNo = phoneNumberSetSpace(mobileNo);
    return stcPayModal(canSaveCard)
      .replace(/{%mobileNo%}/g, mobileNo);
  } else {
    return stcPayModal(canSaveCard)
      .replace(/{%mobileNo%}/g,'');
  }
};

const getStcPayOTPModal = () => {
  return stcPayOTPModal();
};

const getApplePayNotSupportedHtml = () => {
  return applePayNotSupportedHtml();
};

const getApplePayHtml = (applePay, isWalletActive) => {
  if (applePay) {
    return buttonApple(isWalletActive);
  }
};

const getButtonsHtml = (cancel) => {
  let buttons = '';

  buttons+= buttonPay();

  if (cancel) {
    buttons+= cancelPay();
  }
    
  return buttons;
};

const getHtml = () => {
  return html();
};
const getHtmlErorrModal = () => {
  return htmlErorrModal();
};

const getIframeLoader = () => {
  return iframeLoader();
};

const checkSaveCardsExist = (data) => {
  if(data && data.customer_id && data.cards && data.cards.length) {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatus3ds = (response) => {
  if (response && response.status == '3DS' && response.html && response.ws_url && response.reference_number) {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusSuccess = (response) => {
  if (response && response.status == 'success') {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusCanceled = (response) => {
  if (response && response.status == 'canceled') {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusError = (response) => {
  if (response && response.status == 'error') {
    return true;
  } else {
    return false;
  }
};

const checkResponseStatusFailed = (response) => {
  if (response && response.status == 'failed') {
    return true;
  } else {
    return false;
  }
};

const checkRender = (config) => {
  if (config && config.render) {
    return true;
  } else {
    return false;
  }
};


const setMethod = (type, code, submit_url, cancel_url, redirect_url, payment_url, id, ccv_required, can_save_card) => {
  method.type = type;
  method.code = code;
  method.submit_url = submit_url;
  method.cancel_url = cancel_url ? cancel_url : '';
  method.redirect_url = redirect_url ? redirect_url : '';
  method.payment_url = payment_url ? payment_url : '';
  method.id = id ? id : '';
  method.ccv_required = ccv_required ? ccv_required : false;
  method.can_save_card = can_save_card ? can_save_card : false;
  return true;
};


const validateStcFiels = (value, type) => {
  if(!value) {
    return false;
  } else if (value && type == 'phoneNo') {
    const phoneNoRegex = new RegExp(/^(?:\d{10}|\d{12})$/);
    return phoneNoRegex.test(value); 
  }
};

const setEventListenerForRadioButtons = (elements) => {
  for (let i = 0; i < elements.length; i++) {
    if (!elements[i]) {
      continue;
    }

    elements[i].addEventListener('keydown', (e) => {
      if (e.which === 13) {
        elements[i].blur();
      }
    });

    elements[i].addEventListener('input', (e) => {
      let ccvLength = 4;
      const brandName = elements[i].attributes['data-brand'] ? elements[i].attributes['data-brand'].value : '';

      if (brandName === 'AMEX') {
        ccvLength = 4;
      } else {
        ccvLength = 3;
      }
      
      const value = e.target.value.replace(/[^0-9]+/g, '').slice(0, ccvLength);

      e.target.value = value;
      elements[i].classList.remove('ottu__sdk-invalid-input');

      if (elements[i].closest('.ottu__sdk-invalid-input')) {
        elements[i].closest('.ottu__sdk-invalid-input').classList.remove('ottu__sdk-invalid-input');
      }

    });

  }
};

const resetAllSavedCards = (elements) => {
  elements.forEach(element => {

    if (element.closest('.ottu__sdk-invalid-input')) {
      element.closest('.ottu__sdk-invalid-input').classList.remove('ottu__sdk-invalid-input');
    }

    element.value = '';
  });
};

const resetSelectedCheckboxes = (elements, checkbox) => {
  if(elements){
    elements.forEach( element => {
      if (element !== checkbox) {
        if(element.checked)
          element.checked = false;
      }
    });
  }
};

const checkFormsofPayments = (form) => {
  if(mainConfig.formsOfPayment && mainConfig.formsOfPayment.length)
    return mainConfig.formsOfPayment.includes(form) ? true : false;
};

const replaceSdk = (configs) => {
  let oldCheckout = document.getElementById(configs.selector);
  let newCheckout = document.createElement('div');
  newCheckout.setAttribute('id', configs.selector);
  oldCheckout.parentElement.replaceChild(newCheckout, oldCheckout);
};

const calculateSubTotal = (amount, fee) => {
  let subTotal = amount - fee;
  if (amount.includes(".")) {
    let decimalsDigits = amount.split('.')[1].length
    return subTotal.toFixed(decimalsDigits);
  }  
  return subTotal;
};

exports.setConfigs = setConfigs;
exports.ccvValidation = ccvValidation;
exports.getHtml = getHtml;
exports.getApplePayNotSupportedHtml = getApplePayNotSupportedHtml;
exports.getSaveCardsHtml = getSaveCardsHtml;
exports.mobileApplePayHtml = mobileApplePayHtml;
exports.getPaymentMethodsHtml = getPaymentMethodsHtml;
exports.getCardRemoveModalHtml = getCardRemoveModalHtml;
exports.getFailedModalHtml = getFailedModalHtml;
exports.getButtonsHtml = getButtonsHtml;
exports.getApplePayHtml = getApplePayHtml;
exports.getIframeLoader = getIframeLoader;
exports.checkSaveCardsExist = checkSaveCardsExist;
exports.checkResponseStatus3ds = checkResponseStatus3ds;
exports.checkResponseStatusSuccess = checkResponseStatusSuccess;
exports.checkResponseStatusCanceled = checkResponseStatusCanceled;
exports.checkResponseStatusError = checkResponseStatusError;
exports.checkResponseStatusFailed = checkResponseStatusFailed;
exports.checkRender = checkRender;
exports.setMethod = setMethod;
exports.getHtmlErorrModal = getHtmlErorrModal;
exports.resetAllSavedCards = resetAllSavedCards;
exports.setEventListenerForRadioButtons = setEventListenerForRadioButtons;
exports.checkFormsofPayments = checkFormsofPayments;
exports.replaceSdk = replaceSdk;
exports.resetSelectedCheckboxes = resetSelectedCheckboxes;
exports.getStcPayModal = getStcPayModal;
exports.getStcPayOTPModal = getStcPayOTPModal;
exports.validateStcFiels = validateStcFiels;
exports.phoneNumberSetSpace = phoneNumberSetSpace;
exports.calculateSubTotal = calculateSubTotal;