const { mainConfig } = require('./variables');
const langs = {
  ar: {
    'Payment Details': 'تفاصيل الدفع',
    'Total Bill': 'إجمالي المبلغ',
    'Debit / Credit Card': 'بطاقة الصراف الآلي / بطاقة ائتمان',
    'Name On Card': 'الاسم على البطاقة',
    'Card Number': 'رقم البطاقة',
    'Click To Save Your Card': 'احفظ البطاقة',
    'You have incorrectly entered your expiration date': 'لقد أدخلت تاريخ انتهاء الصلاحية بشكل غير صحيح',
    'Saved Cards': 'البطاقات المحفوظة',
    'List of all cards saved': 'قائمة البطاقات المحفوظة',
    'Expires on': 'تنتهي في',
    'Expired on': 'انتهت في',
    'Expired': 'انتهت',
    'Click Confirm if you don\'t want this payment method to be displayed in your payment options': 'إذا كنت لا تريد عرض طريقة الدفع هذه في خيارات الدفع',
    'Delete': 'حذف',
    'Cancel': 'الغاء',
    'Payment Failed': 'فشل عملية الدفع',
    'OK': 'موافق',
    'Remove': 'حذف',
    'Expiry': 'تاريخ انتهاء الصلاحية',
    'CCV': 'رمز التحقق من البطاقة',
    'MM/YY': 'شهر/سنة',
    'Accept & Pay': 'الموافقة والدفع',
    'This field is required': 'حقل مطلوب',
    'Invalid Card Number': 'رقم البطاقة غير صحيح',
    'Payment Method': 'وسيلة دفع',
    'List of all gateways we support': 'قائمة بجميع بوابات الدفع التي ندعمها',
    'Fees': 'رسوم',
    'Total': 'الاجمالي',
    'Sub-Total': 'المجموع',
    'Apple Pay unavailable. Please update your device, setup Wallet, and add an active card and reload the page.':'غير متاح, يرجي تحديث جهازك , اعداد المحفظة, واضافة بطاقة نشطة, واعادة تحميل الصفحة Apple Pay' 
  }
};

const $t = (text) => {
  if (!text) return '';
  text = String(text);
  if (langs[mainConfig.lang] && langs[mainConfig.lang][text]) {
    return langs[mainConfig.lang][text];
  } else {
    return text;
  }
};
exports.$t = $t;