const {
  getFailedModalHtml,
  getIframeLoader,
  checkResponseStatus3ds,
  checkResponseStatusSuccess,
  checkResponseStatusCanceled,
  checkResponseStatusError,
  checkResponseStatusFailed,
  setMethod,
  resetAllSavedCards,
  getApplePayHtml,
  checkFormsofPayments,
  resetSelectedCheckboxes,
  getStcPayModal,
  getStcPayOTPModal,
  validateStcFiels,
  phoneNumberSetSpace,
  ccvValidation,
  calculateSubTotal,
} = require('./util');
  
const { $t } = require('./translate');
const { 
  redirectPayFetch,
  tokenPayFetch,
  deleteTokenFetch,
  getstcPayOtp,
  stcPayFetch
} = require('./http');
const { mainConfig, elements, method } = require('./variables');

class Selector {

  static setSelector(selector) {
    this.documentCheckout = selector;
  }

  static setCurrentScript(currScript) {
    this.curScriptElement = currScript;
  }

  constructor () {
    this.disableFileds = false; 
  }

  static changeTotal (fee, currencyCode, currencyCodedDefault, amount, description) {
    if (fee > 0) {
      this.documentCheckout.querySelectorAll(`.${elements.amountTitle}`).forEach(element => {
        element.innerHTML = $t('Sub-Total');
      });
      
      this.documentCheckout.querySelectorAll(`.${elements.amount}`).forEach(element => {
        element.innerHTML = calculateSubTotal(amount, fee);
      });
      this.documentCheckout.querySelectorAll(`.${elements.currency}`).forEach(element => {
        element.innerHTML = currencyCode;
      });

      this.documentCheckout.querySelectorAll(`.${elements.feesLabel}`).forEach(element => {
        if (description) {
          element.innerHTML = description;
        } else {
          element.innerHTML = $t('Fees');
        }
      });
      
      this.documentCheckout.querySelectorAll(`.${elements.fees}`).forEach(element => {
        element.innerHTML = fee;
      });
      
      this.documentCheckout.querySelectorAll(`.${elements.feesCurrency}`).forEach(element => {
        element.innerHTML = currencyCode;
      });
    
      this.documentCheckout.querySelectorAll('.'+elements.feesBlock).forEach(element => {
        element.style.maxHeight = '50px';
      });
    
      this.documentCheckout.querySelectorAll(`.${elements.total}`).forEach(element => {
        element.innerHTML = amount;
      });
    
      this.documentCheckout.querySelectorAll(`.${elements.totalCurrency}`).forEach(element => {
        element.innerHTML = currencyCode;
      });
    
      this.documentCheckout.querySelectorAll('.'+elements.totalBlock).forEach(element => {
        element.style.maxHeight = '50px';
      });
    } else {
      this.documentCheckout.querySelectorAll(`.${elements.amount}`).forEach(element => {
        element.innerHTML = amount;
      });
      this.documentCheckout.querySelectorAll(`.${elements.currency}`).forEach(element => {
        element.innerHTML = currencyCode;
      });
      this.documentCheckout.querySelectorAll('.'+elements.feesBlock).forEach(element => {
        element.style.maxHeight = '0px';
      });
      this.documentCheckout.querySelectorAll('.'+elements.totalBlock).forEach(element => {
        element.style.maxHeight = '0px';
      });
      this.documentCheckout.querySelectorAll(`.${elements.amountTitle}`).forEach(element => {
        element.innerHTML = $t('Total Bill');
      });
    }
  }

  static renderOnlyApplePay () {
    this.documentCheckout.querySelectorAll('.card-only-display').forEach(element => {
      element.style.display = 'none';
    });
    this.documentCheckout.querySelectorAll('.ottu__sdk-card-mobile').forEach(element => {
      element.style.padding = '0px';
      element.classList.remove('ottu__sdk-card-mobile');  
    });
    this.documentCheckout.querySelector('.ottu__sdk-btns-parents').classList.add('layout-apple-btn');
    this.documentCheckout.querySelector('.ottu__sdk-main').style.padding = '0px';
  }

  static disableEnableFileds (disable, type) {
    const radios = this.documentCheckout.querySelectorAll('input');
    for (let index = 0; index < radios.length; index++) {
      const element = radios[index];
      element.disabled = disable;
    }
    
    const payWithToken = this.documentCheckout.querySelectorAll('.ottu__sdk-pay-with-token');
    for (let index = 0; index < payWithToken.length; index++) {
      const element = payWithToken[index];
      if (disable) {
        element.classList.add('pay-with-token-disable');
      } else {
        element.classList.remove('pay-with-token-disable');
      }
    }
    this.disableFileds = disable;
    const payButton = this.documentCheckout.querySelector('#'+elements.payButton);
    if (payButton && !type) {
      if (disable) {
        payButton.classList.add('loading');
      } else {
        payButton.classList.remove('loading');
      }
    }
  }

  static payButtonClick () {
    if (!this.disableFileds) {
      if (method.type == 'stc_pay') {
        Selector._stcPay();
      } else if(method.type == 'token'){
        Selector._tokenPay();
      } else if (method.type == 'ottu_pg'){
        Selector._ottuPGPay();
      } else if (method.type == 'redirect') {
        Selector._redirectPay();
      } 
    }
  }


  static selectePayMethod (_this) {
    if (this.disableFileds) {
      return ;
    }
    const name = _this.attributes.name.value;
    const type = _this.attributes['data-type'].value;
    const code = _this.attributes[name].value;
    const submitUrl = _this.attributes.submit_url ? _this.attributes.submit_url.value : '';
    const id = _this.attributes['id'] ? _this.attributes['id'].value : '';
    const ccv_required = _this.attributes['data-ccv_required'] ? _this.attributes['data-ccv_required'].value === 'true' : false;
    const fee = _this.attributes['data-fee'] ? _this.attributes['data-fee'].value : '';
    const currencyCode = _this.attributes['data-currency_code'] ? _this.attributes['data-currency_code'].value : '';
    const currencyCodedDefault = _this.attributes['data-currency_code'] ? _this.attributes['data-currency_code_default'].value : '';
    const amount = _this.attributes['data-amount'] ? _this.attributes['data-amount'].value : '';
    const description = _this.attributes['data-description'] ? _this.attributes['data-description'].value : '';
    const cancel_url = _this.attributes['cancel_url'] &&_this.attributes['cancel_url'].value != 'undefined' ? _this.attributes['cancel_url'].value : '';
    const payment_url =  _this.attributes['payment_url'] && _this.attributes['payment_url'].value != 'undefined' ?  _this.attributes['payment_url'].value : '';
    const canSaveCard =  _this.attributes['can-save-card'] && _this.attributes['can-save-card'].value != 'undefined' ?  _this.attributes['can-save-card'].value : false;
    const redirectUrl = _this.attributes['redirect_url'] ? _this.attributes['redirect_url'].value : '';
   
    Selector.changeTotal(fee, currencyCode, currencyCodedDefault, amount, description);
    setMethod(name, code, submitUrl, cancel_url, redirectUrl, payment_url, id, ccv_required, canSaveCard);
    // this.documentCheckout.querySelector('#'+elements.blockPaymentMethodFee).classList.remove('d-none');
    // Selector.changePaymentGatewayFee(fee, currencyCode, description);
  
    this.documentCheckout.querySelector('#'+elements.payButton).classList.remove('ottu__sdk-disabled');
  
    
    this.documentCheckout.querySelectorAll('.'+elements.accordionItem).forEach(element => {
      element.classList.remove('ottu__sdk-border-green');
      element.querySelector('.'+elements.accordionItemHeader).classList.remove('active');
    });
    
    resetAllSavedCards(this.documentCheckout.querySelectorAll('.ottu__sdk-input-ccv'));
        
    resetSelectedCheckboxes(this.documentCheckout.querySelectorAll('.'+elements.accordionItemRadio),_this);
    let checkbox = _this.querySelector('.ottu__sdk-checkbox');
    resetSelectedCheckboxes(this.documentCheckout.querySelectorAll('.ottu__sdk-checkbox'),checkbox);

    this.documentCheckout.querySelectorAll('.ottu__sdk-radio-div').forEach( element => {  
      if (element.querySelector('input').checked) {
        element.querySelector(`.${method.id}`) ? element.querySelector(`.${method.id}`).classList.add('d-block'): '';
      } else {
        element.querySelector('.d-block') ? element.querySelector('.d-block').classList.remove('d-block'): '';
      }
    });
  
    if (type === 'method') {
      _this.classList.toggle('ottu__sdk-border-green');
      _this.querySelector('.'+elements.accordionItemHeader).classList.add('active');
    }
  }

  static _redirectPay () {
    if (checkFormsofPayments('redirect')) {
      Selector.disableEnableFileds(true);

      let paymentMethod = this.documentCheckout.getElementById(method.id);
      const cardSave = paymentMethod.querySelector('#'+elements.cardSaveBlcok) ? paymentMethod.querySelector('#'+elements.cardSaveBlcok).querySelector('input').checked : false;
      const submit_url = window.redirectSubmitUrl ? window.redirectSubmitUrl : method.submit_url;
      
      redirectPayFetch(submit_url, mainConfig.apiKey, method.code, cardSave)
        .then(response => {
          Selector.successResponse(response, 'redirect');
        })
        .catch(err => {
          if (err.status >= 500) {
            Selector.disableEnableFileds(false);
            err = {'status': 'error', 'message': 'Oops, something went wrong. Refresh the page and try again.'};
            Selector.callBackError(err);
          }
          else {
            Selector.errorResponse(err);
          }
        });
    }
  }

  static _stcPay () {
    Selector.disableEnableFileds(true, 'stcPay');
    mainConfig.customer_phone = mainConfig.customer_phone.replace(/[^0-9]+/g, '');
    Selector.showStcModal(getStcPayModal(mainConfig.customer_phone, method.can_save_card),'otp');
  }

  static _ottuPGPay () {
    Selector.disableEnableFileds(true);
    try {
      window.location.href = method.redirect_url;
    } catch (error) {
      Selector.disableEnableFileds(false);
      Selector.errorResponse(error);
    }
  }

  static _tokenPay () {
    Selector.disableEnableFileds(true);
    let cardCCV;
    if (method.ccv_required) {
      this.documentCheckout.querySelectorAll('.ottu__sdk-radio-div').forEach( element => {    
        if (element.querySelector('input').checked) {
          cardCCV = element.querySelector('label').querySelector('input').value;
        }
      });
  
      const validation = ccvValidation(cardCCV);
      if (validation.error) {
        this.documentCheckout.querySelector(`.${method.id}`).classList.add('ottu__sdk-invalid-input');
        this.documentCheckout.querySelector(`.${method.id}`).querySelector('i').innerHTML = validation.errors['tokenCardCcvBlock'];
        Selector.disableEnableFileds(false);
      } else {
        tokenPayFetch(method.submit_url, mainConfig.apiKey, mainConfig.merchant_id, mainConfig.session_id, method.code, cardCCV)
          .then(response => {
            if(response.payment_gateway_info && response.status == 'success'){
              Selector.callBackSuccess(response);
            } else if(response.payment_gateway_info && response.status == 'canceled'){
              Selector.callBackCancel(response);
            }
            else{
              Selector.successResponse(response, 'token');
            }
          })
          .catch(err => {
            if (err.status >= 500) {
              Selector.disableEnableFileds(false);
              err = {'status': 'error', 'message': 'Oops, something went wrong. Refresh the page and try again.'};
              Selector.callBackError(err);
            }
            else{
              Selector.errorResponse(err);
            }
          });
      }
    } else {
      tokenPayFetch(method.submit_url, mainConfig.apiKey, mainConfig.merchant_id, mainConfig.session_id, method.code)
        .then(response => {
          if(response.payment_gateway_info && response.status == 'success'){
            Selector.callBackSuccess(response);
          } else if(response.payment_gateway_info && response.status == 'canceled'){
            Selector.callBackCancel(response);
          }
          else {
            Selector.successResponse(response, 'token');
          }
        })
        .catch(err => {
          if (err.status >= 500) {
            Selector.disableEnableFileds(false);
            err = {'status': 'error', 'message': 'Oops, something went wrong. Refresh the page and try again.'};
            Selector.callBackError(err);
          }
          else{
            Selector.errorResponse(err);
          }
        });
    }
  }

  static successResponse (response, type, stcPay) {
    if (stcPay) {
      var modal = Selector.documentCheckout.querySelector('#'+elements.modal);
      Selector.disableEnableFileds(false, 'stcPay');
    } else {
      Selector.disableEnableFileds(false);
    }
    if (checkResponseStatus3ds(response)) {
      Selector.generate3DSPopUp(response);
    } else if(checkResponseStatusSuccess(response)) {
      if (type === 'redirect') {
        Selector.callBackRedirect(response);
      } else {
        if (stcPay) {
          Selector.hideModal();
        }
        Selector.callBackSuccess(response);
      } 
    } else if(checkResponseStatusCanceled(response) || checkResponseStatusFailed(response)) {
      if (stcPay) {
        modal.querySelector('#'+elements.stcOTPBlock).classList.add('ottu__sdk-invalid-input');
        Selector.showStcErrorMessage(modal, response);
      } 
        Selector.callBackCancel(response);    
    } else if(checkResponseStatusError(response)) {
      if (stcPay) {
        modal.querySelector('#'+elements.stcOTPBlock).classList.add('ottu__sdk-invalid-input');
        Selector.showStcErrorMessage(modal, response);
      }
      Selector.callBackError(response);
    } else {
      Selector.callBackError({
        status: 'error',
        message: 'incorrect response'
      });
    }
  }

  static showHide3ds (show) {
    const sdk = this.documentCheckout.querySelector('#'+elements.sdkChild);
    const threeDS = this.documentCheckout.querySelector('#'+ elements.threeDS)
    if (show) {
      sdk.style.display = 'none';
    } else {
      threeDS.innerHTML = '';
      sdk.style.display = 'flex';
    }
  }

  static generate3DSPopUp (response) {
    const wsUrlPattern = new RegExp(/(\S+\.)?ottu\.(dev|net)/);
    if(!wsUrlPattern.test(response.ws_url)) {
      Selector.callBackError({
        status: 'error',
        message: 'Invalid WS_URL'
      });
    } else {
      const _this = this;
      const sdk = _this.documentCheckout.querySelector('#'+elements.sdkChild);
      const threeDS = _this.documentCheckout.querySelector('#'+ elements.threeDS)
      if (sdk && threeDS) {
        Selector.showHide3ds(true, sdk, threeDS);
        threeDS.innerHTML = `${getIframeLoader()}<div id="dsIframe" class="ottu__sdk-ds-block 3ds-container hide">${response.html}</div>`;
        const formElement = threeDS.getElementsByTagName('form');

        let formId = 'redirectTo3ds1Form';
        if (formElement.length) {
          formId = formElement[0].id;
        }
    
        eval(`var e=this.documentCheckout.getElementById("${formId}"); console.log(e, 'e'); if (e) { e.submit(); if (e.parentNode !== null) { e.parentNode.removeChild(e); } }`);
        let count = 0;
        threeDS.querySelector('iframe').onload = function() {
          if (count == 0) {
            _this.documentCheckout.getElementById('dsIframe').classList.remove('hide');
            _this.documentCheckout.getElementById(elements.blockIframeLoader).classList.add('hide');
          } else if (count == 1) {
            _this.documentCheckout.getElementById('dsIframe').classList.add('hide');
            _this.documentCheckout.getElementById(elements.blockIframeLoader).classList.remove('hide');
          }
          count++;
        };
      
        const socket = new WebSocket(response.ws_url);
        socket.addEventListener('message', function (event) {
          let res = JSON.parse(event.data);
          if(checkResponseStatusSuccess(res)) {
            Selector.callBackSuccess(res);
          } else if(checkResponseStatusCanceled(res)) {
            Selector.callBackCancel(res)
            Selector.showHide3ds(false);
          } else if(checkResponseStatusError(res)) {
            Selector.callBackError(res);
            Selector.showHide3ds(false);
          } else {
            Selector.callBackError({
              status: 'error',
              message: 'ws incorrect response '
            });
            Selector.showHide3ds(false);
          }
          socket.close();
          Selector.disableEnableFileds(false);
        });
        socket.addEventListener('open', function () {
          socket.send(`{"client_type": "sdk", "reference_number": "${response.reference_number}", "merchant_id": "${mainConfig.merchant_id}"}`);
        });
        socket.addEventListener('close', function () {
          
        });
      }
    }
  }

  static errorResponse (err) {
    if (typeof err === 'object' && typeof err.then === 'function') {
      err.then((error) => {
        if (error.non_field_errors && error.non_field_errors[0]) {
          Selector.showModal(getFailedModalHtml(error.non_field_errors[0]));
        } else if (error.detail) {
          Selector.showModal(getFailedModalHtml(error.detail || 'Something Went Wrong.'));
        } else {
          console.log(error);
        }
        Selector.disableEnableFileds(false);
      });
    } else {
      Selector.callBackError({
        status: 'error', message: 'Server error responses status ' + err
      });
    }
  }

  static callBackSuccess (response) {
    if (!response.redirect_url)
      response.redirect_url = window.location.href;

    if(Selector.checkCallback('data-success','successCallback')) {
      if (window[this.curScriptElement.dataset.success]) {
        window[this.curScriptElement.dataset.success](response);
      }
    } else {
      console.warn('WARNING: Success callback is not configured');
    }
  
  }

  static callBackRedirect (response) {
    if (window[this.curScriptElement.dataset.beforeredirect]) {
      window[this.curScriptElement.dataset.beforeredirect](response).then(
        function() {
          window.location.href = response.redirect_url;
        },
        function(error) {
          console.log(error);
        }
      );
    } else {
      window.location.href = response.redirect_url;
    }
  }
 
  static callBackError (response) {
    if (Selector.checkCallback('data-error','errorCallback')) {
      if (window[this.curScriptElement.dataset.error]) {
        window[this.curScriptElement.dataset.error](response);
      }
    } else {
      console.warn('WARNING: Error callback is not configured');
    }
  
  }

  static checkCallback (data) {
    const scriptDocument = document.querySelector(`script[src='${process.env.SERVER_URL}/v2/checkout.min.js']`);
  
    if (!(scriptDocument && scriptDocument.getAttribute(data))) {    
      return false;
    }
  
    return true;
  }

  static showModal (html, response) {
    const modal = Selector.documentCheckout.querySelector('#'+elements.modal);
    const modalOverlay =  Selector.documentCheckout.querySelector('#'+elements.modalOverlay);
    modal.innerHTML = html;
    modal.classList.add('ottu__sdk-active');
    modalOverlay.classList.add('ottu__sdk-active');
    modal.querySelectorAll('.closeBtn').forEach(closeBtns => {
      closeBtns.addEventListener('click', function() {
        if (response && response.status == 'canceled' ) {
          Selector.callBackCancel(response)
        } else if (response && response.status == 'error') {
          Selector.callBackError(response);
        }
        Selector.hideModal();
      }, true);
    });
  }
    
  static showStcModal (html, type) {
    const modal = Selector.documentCheckout.querySelector('#'+elements.modal);
    const modalOverlay =  Selector.documentCheckout.querySelector('#'+elements.modalOverlay);
    modal.innerHTML = html;
    modal.classList.add('ottu__sdk-active');
    modalOverlay.classList.add('ottu__sdk-active');
    var phoneNumber, canSaveCard;
    
    if (type == 'otp') {
      let mobileNoBlock =  modal.querySelector('#'+elements.stcPayMobileNumberBlock); 
      mobileNoBlock.querySelector('input').addEventListener('input', function(e) {
        mobileNoBlock.classList.remove('ottu__sdk-invalid-input');
        modal.querySelector('i').innerHTML = '';
        e.target.value = e.target.value.replace(/[^0-9]+/g, '').slice(0, 12);
        e.target.value = phoneNumberSetSpace(e.target.value);
      });

      modal.querySelector('.ottu__sdk-close-btn').addEventListener('click', () => {
        Selector.hideModal();
        Selector.disableEnableFileds(false, 'stcPay');
        Selector.callBackCancel({'status':'canceled','message':'Payment Cancelled By User'});
      });

      modal.querySelectorAll('.sendOtpBtn').forEach(sendBtn => {
        if(sendBtn) {
          sendBtn.addEventListener('click', () => {
            phoneNumber = modal.querySelector('#'+elements.stcPayMobileNumber).value.replace(/ /g, '');
            canSaveCard = modal.querySelector('#'+elements.cardSaveBlcok) ? modal.querySelector('#'+elements.cardSaveBlcok).querySelector('input').checked : false;
            const valid = validateStcFiels(phoneNumber, 'phoneNo');
            if (valid) {
              modal.querySelector('.ottu__sdk-stc-loader').classList.add('active');
              Selector.disableEnableFileds(true, 'stcPay');
              getstcPayOtp(method.submit_url, mainConfig.apiKey, mainConfig.session_id, method.code, phoneNumber, canSaveCard)
                .then(() => {
                    modal.querySelector('.ottu__sdk-stc-loader').classList.remove('active');
                    Selector.showStcModal(getStcPayOTPModal(),'pay');
                })
                .catch(err => {
                  mobileNoBlock.classList.add('ottu__sdk-invalid-input');
                  Selector.showStcErrorMessage(modal, err);
                });
            } else {
              mobileNoBlock.classList.add('ottu__sdk-invalid-input');
              modal.querySelector('i').innerHTML = $t("Please Enter a valid Phone Number");
            }  
          },true);}
      });
    } else if (type == 'pay') {
      let otpBlock =  modal.querySelector('#'+elements.stcOTPBlock); 
      otpBlock.querySelector('input').addEventListener('input', function(e) {
        otpBlock.classList.remove('ottu__sdk-invalid-input');
        e.target.value = e.target.value.replace(/[^0-9]+/g, '');
      });
        
      modal.querySelector('.ottu__sdk-back-btn').addEventListener('click', () => {
        Selector.showStcModal(getStcPayModal(mainConfig.customer_phone, method.can_save_card),'otp');
      });

      modal.querySelectorAll('.stcPayBtn').forEach(payBtn => {
        if(payBtn) {
          payBtn.addEventListener('click', () => {
            let otpValue = modal.querySelector('#'+elements.stcPayOTP).value.replace(/ /g, '');
            if (otpValue) {
              modal.querySelector('.ottu__sdk-stc-loader').classList.add('active');
              Selector.disableEnableFileds(true, 'stcPay');
            stcPayFetch(method.payment_url, mainConfig.apiKey, mainConfig.session_id, otpValue)
              .then(response => {
                modal.querySelector('.ottu__sdk-stc-loader').classList.remove('active');
                Selector.successResponse(response, '', true);
              })
              .catch(err => {
                otpBlock.classList.add('ottu__sdk-invalid-input');               
                Selector.showStcErrorMessage(modal, err);
              });
            } else {
              otpBlock.classList.add('ottu__sdk-invalid-input');
              modal.querySelector('i').innerHTML = $t('This field is required');
            }
            },true);}
          });
    }
  }

  static hideModal () {
    const modal = Selector.documentCheckout.getElementById(elements.modal);
    const modalOverlay =  Selector.documentCheckout.getElementById(elements.modalOverlay);
    modal.classList.remove('ottu__sdk-active');
    modalOverlay.classList.remove('ottu__sdk-active');
    modal.innerHTML = '';
  }

  static callBackCancel (response) {
    if (Selector.checkCallback('data-cancel','cancelCallback') && window[this.curScriptElement.dataset.cancel]) {
      window[this.curScriptElement.dataset.cancel](response);
    } else {
      console.warn('WARNING: Cancel callback is not configured');
    }
  }

  static pay ({ token }) {
    setMethod('token', token, process.env.SUBMIT_URL);
    Selector._tokenPay();
  }

  static _cancelPay () {
    Selector.disableEnableFileds(true);
    
    cancelFetch(method.cancel_url, mainConfig.apiKey ,mainConfig.merchant_id, mainConfig.session_id)
      .then(response => {
        if (response.status === 'canceled') {
          window.location.href = response.redirect_url;
        } else {
          Selector.callBackError(response);
        }
      })
      .catch(err => {
        err.then(() => {
          Selector.callBackError({
            status: 'error',
            message: ''
          });
        });
      });
  }

  static _deleteToken (url, radioDiv) {
    Selector.disableEnableFileds(true);
    deleteTokenFetch(url, mainConfig.apiKey).then(() => {
      Selector.hideModal();
      radioDiv.remove();
      Selector.disableEnableFileds(false);
    })
      .catch(err => {
        Selector.hideModal();
        Selector.errorResponse(err);
      });
  }

  static changeButtonLayout (applePay , googlePay){
    if(applePay || googlePay){
      let css = document.createElement('style');
      css.innerHTML =  '.layoutButtons{flex:1}';
      let sdk_child = this.documentCheckout.querySelector('#'+elements.sdkChild);
      sdk_child.appendChild(css);
    }
  }
  
  static showAppleButton (applePay, isWalletActive) {
    if(applePay) {
      this.documentCheckout.querySelectorAll(`#${elements.blockAppleButton}`).forEach(element => {
        element.innerHTML = getApplePayHtml(applePay, isWalletActive);
        element.classList.remove('d-none');
      });
    }

  }

  static showGoogleButton (googlePay, button, mobileButton,) {
    button.classList.add('google-pay-button');
    button.firstChild ? button.firstChild.classList.add('google-pay-button-animation') : '';  

    mobileButton.classList.add('google-pay-button');
    mobileButton.firstChild ? mobileButton.firstChild.classList.add('google-pay-button-animation') : '';

    if(googlePay) {
      let mobileBlock = this.documentCheckout.querySelector(`#${elements.blockMobileGoogleButton}`);
      mobileBlock.innerHTML = '';
      mobileBlock.appendChild(mobileButton);
      mobileBlock.classList.remove('d-none');

      let block = this.documentCheckout.querySelector(`#${elements.blockGoogleButton}`);
      block.innerHTML = '';
      block.appendChild(button);
      block.classList.remove('d-none');
    }
  }

  static showStcErrorMessage (modal, err) {
    modal.querySelector('.ottu__sdk-stc-loader').classList.remove('active');
    this.disableEnableFileds(false, 'stcPay');
    if (err.status >= 500) {
      modal.querySelector('i').innerHTML = "Something Went Wrong";
    } else if (typeof err === 'object' && typeof err.then === 'function') {
      err.then((error) => {
        if(error.message) {
          modal.querySelector('i').innerHTML = error.message;
        } 
      });
    } else if(err.message) {
        modal.querySelector('i').innerHTML = err.message;
    }
  }
}

exports.Selector = Selector;