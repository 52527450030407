const sdkFetch = window.fetch;
const getPaymentDetails = (merchant_id, session_id, apiKey) => {
  return new Promise((resolve,reject) => {
    let url = `https://${merchant_id}/b/checkout/api/sdk/v1/pymt-txn/submit/${session_id}`;
  
    sdkFetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Api-Key ${apiKey}`,
      },
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};
exports.getPaymentDetails = getPaymentDetails;

const redirectPayFetch = (submit_url, apiKey, code , save_card) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify({
        pg_code:code,
        save_card: save_card
      })
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.redirectPayFetch = redirectPayFetch;

const getstcPayOtp = (submit_url, apiKey, session_id, code, customer_phone, save_card) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify({
        customer_phone: customer_phone,
        session_id: session_id,
        pg_code: code,
        save_card: save_card,
      }),
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.getstcPayOtp = getstcPayOtp;

const stcPayFetch = (submit_url, apiKey, session_id, otp) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify({
        session_id: session_id,
        otp: otp,
      }),
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.stcPayFetch = stcPayFetch;

const tokenPayFetch = (submit_url, apiKey, merchant_id, session_id, code, cvv) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify({
        merchant_id: merchant_id,
        session_id: session_id,
        payment_method:'token',
        token: code,
        cvv: cvv
      }),
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.tokenPayFetch = tokenPayFetch;

const deleteTokenFetch = (submit_url, apiKey) => {
  return new Promise((resolve,reject) => {
    sdkFetch(submit_url, {
      method: 'DELETE',
      headers: {
        Authorization: `Api-Key ${apiKey}`,
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response);
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.deleteTokenFetch = deleteTokenFetch;

const cancelFetch = (cancel_url, apiKey, merchant_id, session_id) => {
  return new Promise((resolve,reject) => {
    sdkFetch(cancel_url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Api-Key ${apiKey}`,
      },
      body: JSON.stringify({
        merchant_id: merchant_id,
        session_id: session_id
      })
    })
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          if (response.status >= 500) {
            reject(response);
          } else {
            reject(response.json());
          }
        }
      });
  });
};
exports.cancelFetch = cancelFetch;